import { gsap } from 'gsap';

export default function Hero(el, {
    mainWrapper,
    spotlightHandle,
    spotlightImage,
    spotlightButton,
}) {
    const main = el.querySelector(mainWrapper);
    const cont = main.querySelector(spotlightHandle);
    const image = cont.querySelectorAll(spotlightImage);
    const button = main.querySelector(spotlightButton);

    function handleAnimate() {
        image.forEach((animate) => {
            let animateCircle = gsap.timeline({
                trigger: animate,
                scrub: true,
                paused: true,
                opacity: 0
            });

            let mm = gsap.matchMedia();

            mm.add("(min-width: 992px)", () => {
                animateCircle.fromTo(
                    ".circle", { cx: '55%', r: 325 }, { r: 475 }, animate
                );
            });

            mm.add("(min-width: 1280px)", () => {
                animateCircle.fromTo(
                    ".circle", { cx: '62%', r: 325 }, { r: 475 }, animate
                );
            });

            mm.add("(min-width: 1921px)", () => {
                animateCircle.fromTo(
                    ".circle", { cx: '59%', r: 325 }, { r: 475 }, animate
                );
            });

            button.addEventListener('mouseenter', () => animateCircle.play());
            button.addEventListener('mouseleave', () => animateCircle.reverse());
        });
    }

    handleAnimate();
}